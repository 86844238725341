<template>
  <footer class="footer">
    <Section background="dark-blue">
      <div class="footer__blocks">
        <template v-for="block in props.blocks">
          <template v-if="block.__typename === 'LogoLinkBoxesBlock'">
            <div class="footer__top">
              <div class="footer__top-left">
                <div class="footer__logo" />
                <h3>{{ block.title }}</h3>
                <CTA v-if="block.cta" :text="block.cta" :link="block.cta_link" :outline="block.cta_outline" :color="block.cta_color" />
              </div>
              <div class="footer__top-right">
                <ul>
                  <li v-for="box in block.boxes">
                    <Icon :name="box?.icon || 'empty'" />
                    <div v-html="box?.content"></div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <template v-if="block.__typename === 'FormBlock'">
            <Form v-if="block.form?.uuid" v-bind="mapFormBlockToForm(block)" />
          </template>
          <template v-if="block.__typename === 'LinkListBlock'">
            <div class="footer__link-lists">
              <div v-for="list in block.lists" class="desktop-only">
                <h3>{{ list?.title }}</h3>
                <ul>
                  <li v-for="link in list?.links">
                    <nuxt-link :to="getLinkUrl(link)" :external="isLinkExternal(link)" :target="isLinkExternal(link) ? '_blank' : undefined" class="link">
                      {{ getLinkLabel(link) }}
                    </nuxt-link>
                  </li>
                </ul>
              </div>
              <Accordion :items="block.lists?.map(it => `${it?.title}`) || []" class="mobile-only">
                <template v-for="(list, i) in block.lists" v-slot:[`content-${i}`]>
                  <ul>
                    <li v-for="link in list?.links">
                      <nuxt-link :to="getLinkUrl(link)" :external="isLinkExternal(link)" :target="isLinkExternal(link) ? '_blank' : undefined" class="link">
                        {{ getLinkLabel(link) }}
                      </nuxt-link>
                    </li>
                  </ul>
                </template>
              </Accordion>
            </div>
          </template>
          <template v-if="block.__typename === 'LinkListSimpleBlock'">
            <div class="footer__links">
              <nuxt-link v-for="link in block.links" :to="getLinkUrl(link)" :external="isLinkExternal(link)" :target="isLinkExternal(link) ? '_blank' : undefined" class="link">
                {{ getLinkLabel(link) }}
              </nuxt-link>
            </div>
          </template>
          <!-- <template v-if="block.__typename === 'SeparatorBlock'">
            <Separator fullwidth light />
          </template> -->
        </template>
      </div>

      <div class="footer__bottom">
        <div class="footer__legal">
          © 1390–{{ new Date().getFullYear() }}. {{ $t('footer.legal') }}
        </div>
        <div class="footer__bottom-logo" />
        <div class="footer__social">
          <SocialsWidget :items="socials" />
        </div>
      </div>
    </Section>
  </footer>
</template>

<script lang="ts" setup>
import type { FooterBlocksFragment } from '#gql'
import { mapFormBlockToForm } from '~/lib/data-mappings'
import { path } from '~/lib/helpers'

const props = defineProps<{
  blocks: FooterBlocksFragment[]
}>()

const getLinkUrl = (link): string => path(link?.full_url || link?.model?.url || link?.model?.slug)
const getLinkLabel = (link): string => link?.model?.title || link?.model?.name || link?.name || ''
const isLinkExternal = (link): boolean => link?.model?.__typename === 'File'

const socials = computed(() => (props.blocks?.find(it => it.__typename === 'SocialFooterBlock') as any)?.socials || [])
</script>

<style lang="scss">
.footer {
  min-height: 200px;

  &__blocks {
    display: flex;
    flex-direction: column;
    gap: _rem(64px);

    @include breakpoint(small down) {
      gap: _rem(32px);
    }
  }

  &__top {
    display: flex;
    align-items: center;

    @include breakpoint(small down) {
      flex-direction: column;
      align-items: start;
      gap: _rem(48px);
      margin-top: _rem(8px);
    }

    &-left {
      flex: 1;
      h3 {
        margin: _rem(24px) 0;
        max-width: _rem(550px);
        padding-right: _rem(48px);
      }
    }
    &-right {
      flex: 1;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: _rem(16px);

        @include breakpoint(small down) {
          font-size: _rem(14px);
        }
      }
    }
  }

  &__logo {
    background-image: url('/images/logo-white.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: _rem(96px);
    width: auto;
    margin: 0 auto;
  }

  &__link-lists {
    display: flex;
    flex-wrap: wrap;
    gap: _rem(32px);

    .desktop-only {
      flex: 1 0;

      @include breakpoint(medium down) {
        flex: 1 0 40%;
      }

      h3 {
        font-size: _rem(24px);
        margin-bottom: _rem(32px);
      }

    }
    ul {
      list-style: none;
      font-size: _rem(14px);
      font-weight: 500;
      padding: 0;

      li {
        margin-bottom: _rem(8px);
      }
    }

    .mobile-only {
      width: 100%;

      ul {
        padding-left: _rem(24px);
      }

      .accordion__item {
        border-color: var(--color-light-32-lines);
      }
    }
  }

  &__links {
    display: flex;
    gap: _rem(32px);
    padding: _rem(64px) 0;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--color-light-32-lines);
    border-bottom: 1px solid var(--color-light-32-lines);
    font-size: _rem(14px);
    font-weight: 500;

    @include breakpoint(large down) {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include breakpoint(small down) {
      border-top: none;
      display: none;
    }
  }

  &__bottom-logo {
    background-image: url('/images/logo-white.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left center;
    aspect-ratio: 0.785;
    height: _rem(64px);
    width: auto;

    @include breakpoint(xsmall down) {
      display: none;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: _rem(64px);

    @include breakpoint(xsmall down) {
      flex-direction: column-reverse;
      gap: _rem(16px);
      align-items: start;
    }
  }

  &__legal {
    font-size: _rem(14px);
    flex: 1 0 calc(50% - _rem(32px));
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 0 calc(50% - _rem(32px));
  }
}
</style>