<template>
  <Navigation v-if="commonData?.navigation"
    :items="(commonData?.navigation as MenuItem[]) || []"
    :alt-lang-path="altLangPath"
  />
  <main>
    <slot />
  </main>
  <Footer :blocks="(commonData?.footer as FooterBlocksFragment[]) || []" />
</template>

<script setup lang="ts">
import type { FooterBlocksFragment } from '#gql'
import type { MenuItem } from '~/components/Navigation.vue'
import { throwFetchError } from '~/lib/errors'

const { locale } = useI18n()
const runtimeConfig = useRuntimeConfig()

// Fetch header & footer; using LazyAsyncData to avoid blocking child components
useGqlHeaders({ 'Content-Language': locale.value })
const { data: commonData, error } = await useLazyAsyncData('commons', () => GqlGetCommons())
watch( error, (value) => value && throwFetchError() )

// Handle language stuff
const localePath = useLocalePath()
const route = useRoute()
const altLangPath = ref('')
const altLocale = computed(() => locale.value === 'cs' ? 'en' : 'cs')

const updateAltLangPath = async () => {
  if (route.meta.id) {
    useGqlHeaders({ 'Content-Language': altLocale.value })
    const { data } = await useAsyncGql('GetPageableById', { id: route.meta.id as string })
    const fetchedPath = data.value?.pageableById?.full_url
  
    altLangPath.value = fetchedPath
      ? `/${data.value?.pageableById?.full_url}`
      : localePath('/', altLocale.value)
  } else { // Homepage
    altLangPath.value = localePath('/', altLocale.value)
  }
}

updateAltLangPath()
watch(() => route.path, updateAltLangPath)
watch(() => route.meta.id, updateAltLangPath)

const currentUrl = computed(() => runtimeConfig.public.appHost + route.path)

// Update head
useHead({
  htmlAttrs: {
    lang: locale
  },
  link: [
    {
      rel: 'alternate',
      hreflang: 'cs',
      href: locale.value === 'cs' ? currentUrl.value : runtimeConfig.public.appHost + altLangPath.value
    },
    {
      rel: 'alternate',
      hreflang: 'en',
      href: locale.value === 'en' ? currentUrl.value : runtimeConfig.public.appHost + altLangPath.value
    }
  ]
})
</script>
