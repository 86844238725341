<template>
  <div ref="el" :class="classes">
    <div class="nav-menu__overlay" />
    <div class="nav-menu__inner">
      <div class="nav-menu__left">
        <h2 v-check-overflow>{{ props.title }}</h2>
        <nuxt-link v-if="!props.mobile && props.slug && props.slug !== '#'"
          :to="path(props.ctaLink || props.slug)"
          @click="emit('close')"
          class="btn btn--gold"
        >
          {{ $t('navigation.more_info') }} 
        </nuxt-link>
        <ul class="nav-menu__sidelinks">
          <li v-for="item in props.sidelinks">
            <nuxt-link :to="path(item.cta_link)" class="link">
              {{ item.cta }}
            </nuxt-link>
          </li>
        </ul>
      </div>

      <!-- First column with items -->
      <div class="nav-menu__center" v-check-overflow>
        <div class="nav-menu__items-wrapper">
          <h4 v-if="isFirstColGrouped">{{ sortedItems[0]?.title }}</h4>
          <ul class="nav-menu__items">
            <li v-for="item in (firstColItems)" class="nav-menu__link">
              <nuxt-link :to="path(item.model?.full_url || item.slug)" @click="emit('close')">
                <span>{{ getItemTitle(item) }}</span>
                <span class="btn btn--white btn--circle"><Icon name="arrow-forward" /></span>
              </nuxt-link>
            </li>
          </ul>
        </div>

        <!-- Render second col items in the same list under mobile layout and small windows -->
        <div v-if="(props.mobile || collapseCols) && secondColItems.length" class="nav-menu__items-wrapper">
          <h4 v-if="isSecondColGrouped">{{ sortedItems[1]?.title }}</h4>
          <ul class="nav-menu__items" :style="{ '--extra-delay': `${firstColItems.length * 100}ms` }">
            <li v-for="item in secondColItems" class="nav-menu__link">
              <nuxt-link :to="path(item.model?.full_url || item.slug)" @click="emit('close')">
                <span>{{ getItemTitle(item) }}</span>
                <span class="btn btn--white btn--circle"><Icon name="arrow-forward" /></span>
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- Second column with items -->
      <div v-if="!props.mobile" class="nav-menu__right" v-check-overflow>
        <h4 v-if="isSecondColGrouped">{{ sortedItems[1]?.title }}</h4>
        <ul class="nav-menu__items">
          <li v-for="item in secondColItems" class="nav-menu__link">
            <nuxt-link :to="path(item.model?.full_url || item.slug)" @click="emit('close')">
              <span>{{ getItemTitle(item) }}</span>
              <span class="btn btn--white btn--circle"><Icon name="arrow-forward" /></span>
            </nuxt-link>
          </li>
        </ul>
      </div>

      <div v-if="props.mobile" class="nav-menu__mobile-footer">
        <nuxt-link v-if="props.ctaLink && props.slug !== '#'"
          :to="path(props.ctaLink || props.slug)"
          @click="emit('close')"
          class="btn btn--gold"
        >
          {{ $t('navigation.more_info') }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSwipe, useWindowSize } from '@vueuse/core'
import type { MenuItem } from './Navigation.vue'
import { path } from '~/lib/helpers'

const props = defineProps<{
  title: string
  slug?: string | null
  ctaLink?: string
  sidelinks?: any[]
  items?: MenuItem[]
  mobile?: boolean
}>()

const emit = defineEmits(['close'])

const el = ref<HTMLElement | null>(null)
const revealed = ref(false)

const sortItems = (items: MenuItem[]) => items.sort((a, b) => (a.order || 0) - (b.order || 0))
const sortedItems = computed(() => sortItems(props.items || []))

const isFirstColGrouped = computed(() => sortedItems.value[0]?.slug === '#' && sortedItems.value[0]?.children?.length)
const isSecondColGrouped = computed(() => sortedItems.value[1]?.slug === '#' && sortedItems.value[1]?.children?.length)

const firstColItems = computed(() => (
  isFirstColGrouped.value
    ? sortItems(sortedItems.value[0].children || [])
    : sortedItems.value?.slice(0, 5) || []
))

const secondColItems = computed(() => (
  isSecondColGrouped.value
    ? sortItems(sortedItems.value[1].children || [])
    : sortedItems.value?.slice(5) || []
))

const collapseCols = computed( () => useWindowSize().width.value <= 1014 )

const { direction, lengthX } = useSwipe(el, {
  onSwipe() {
    direction.value === 'right' && Math.abs(lengthX.value) > 40 && emit('close')
  }
})

const getItemTitle = (item: MenuItem) => (
  (item.model?.model as any)?.hero_settings?.hero_nav_title || item.title || ''
)

// Setting revealed class with some delay to prevent animation stutter on some elements
onMounted( () => setTimeout(() => revealed.value = true, 1000) )

const classes = computed(() => [
  'nav-menu',
  { 'nav-menu--mobile': props.mobile },
  { 'nav-menu--revealed': revealed.value },
  { 'nav-menu--collapse-cols': collapseCols.value },
  { 'nav-menu--three-col': !collapseCols.value && secondColItems.value.length }
])
</script>

<style lang="scss">
@keyframes navmenu-shadows {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.nav-menu {
  $_: &;
  top: 0;
  left: 0;
  width: 100%;
  min-height: _rem(300px);
  max-height: 100vh;
  position: absolute;
  padding: _rem(220px) var(--page-margin) _rem(70px);
  color: var(--color-white);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__overlay {
    @include absolute-cover;
    background-color: var(--color-crimson);
    transition: opacity 200ms ease, transform 400ms var(--ease-smooth-in);
    box-shadow: var(--sticky-shadow);

    .v-enter-from & {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  }

  &__inner {
    display: flex;
    max-width: var(--page-width);
    margin: 0 auto;
    gap: _rem(58px);
    position: relative;
    container-type: inline-size;
    max-height: calc(100vh - _rem(240px));
    padding-bottom: _rem(10px);

    @include breakpoint(large down) {
      gap: _rem(24px);
    }

    // Edge shadow for scroll
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: _rem(32px);
      background: linear-gradient(to top, var(--color-crimson) 50%, transparent 100%);
      opacity: 0;
      animation: navmenu-shadows 200ms linear 300ms forwards;
    }

    h4 {
      font-weight: 400;
      font-size: _rem(18px);
      padding-bottom: _rem(20px);
      position: sticky;
      top: 0;
      z-index: 10;

      // Edge shadow for scroll
      &::before {
        content: "";
        @include absolute-cover;
        background: linear-gradient(to bottom, var(--color-crimson) 68%, transparent 100%);
        opacity: 0;
        animation: navmenu-shadows 200ms linear 300ms forwards;
        z-index: -1;
      }

      + .nav-menu__items { margin-top: _rem(-16px); }
    }
  }

  &__sidelinks {
    margin-top: _rem(24px) !important;

    li {
      margin-bottom: _rem(16px);
      font-weight: 500;
    }

    a {
      width: 100%;
      display: inline-block;
    }
  }

  &__left {
    flex: 1.5;
    overflow: hidden;
    position: sticky;
    top: 0;

    h2 {
      margin-bottom: _rem(24px);
      width: 54cqw;

      @include breakpoint(large down) {
        font-size: _rem(36px);
      }

      &.is-overflown-h {
        transform: scale(0.97);
        transform-origin: left top;
      }
    }

    > * {
      transition: all 250ms var(--ease-smooth-in);
      .v-enter-from & {
        opacity: 0;
        transform: translate3d(-0.5em , 0, 0);
      }
    }
  }
    &--three-col &__left {
      flex: 1;
      h2 { max-width: 30cqw; }
    }
    &--collapse-cols &__left {
      flex: 1;
      h2 { max-width: 47cqw; }
    }

  &__center, &__right {
    overflow: hidden;
    transition: all 500ms ease;

    &.is-overflown-v {
      padding-right: _rem(16px);
      margin-right: _rem(-16px);
    }
  }
    &--revealed &__center,
    &--revealed &__right {
      overflow-y: auto;
    }

  &__center {
    flex: 1;
  }
  
  &__right {
    flex: 0;
    opacity: 0;
  }
    &--three-col &__right {
      flex: 1;
      opacity: 1;
    }

  &__items-wrapper {
    & + & h4 { margin-top: _rem(36px); }
  }

  &__link {
    @include heading-font;
    @include generate-delays(8, 100ms);
    font-size: _rem(24px);
    position: relative;
    transition-duration: 200ms;
    transition-property: opacity, transform;
    transition-timing-function: var(--ease-smooth-in);
    transition-delay: calc(var(--delay, 0ms) + var(--extra-delay, 0ms));

    @include breakpoint(large only) {
      font-size: _rem(20px);
    }

    // Link separator line
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      opacity: 0.5;
      background-color: var(--color-white);
      transition: width 200ms var(--ease-smooth-in) calc(var(--delay, 0ms) * 1.5 + var(--extra-delay, 0ms));
    }

    .v-enter-from & {
      opacity: 0;
      transform: translate3d(0, 0.5em, 0);
      &::after { width: 0 }
    }

    a {
      display: flex;
      padding: _rem(16px) 0;
      white-space: nowrap;
      align-items: center;
      justify-content: space-between;
      transition: all 150ms ease;
      opacity: 0.5;

      @include breakpoint(large only) {
        padding: _rem(12px) 0;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        width: _rem(19px);
        height: _rem(19px);
      }

      .btn {
        opacity: 0;
        transform: scale(0.9);
        transition: all 150ms ease;
      }

      &:hover {
        opacity: 1;
        .btn {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  /* ----------- MOBILE ----------- */
  &--mobile {
    z-index: 1;
    padding: _rem(157px) 0 _rem(24px);
    height: 100%;
    
    .mobile-open & {
      transition: all 400ms ease;
    }

    #{$_}__center { flex: 1 0; }
    #{$_}__overlay, #{$_}__sidelinks { display: none; }

    #{$_}__left {
      flex: 0;
      top: 0;
      position: sticky;
      padding: 0 0 _rem(10px) _rem(28px);
      background-color: var(--color-crimson);
      overflow: visible;
      z-index: 1;

      h2 {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }

      // Edge shadow for scroll
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: _rem(16px);
        background: linear-gradient(180deg, var(--color-crimson) 0%, transparent 100%);
        z-index: 10;
      }
    }

    #{$_}__inner {
      padding: 0 var(--page-margin);
      flex-direction: column;
      gap: 0;
      // overflow-y: auto;
      max-height: calc(100vh - _rem(180px)); // for legacy browsers without dvh
      max-height: calc(100dvh - _rem(140px));
      height: 100%;

      h4 {
        padding-top: _rem(20px);
      }
    }

    #{$_}__items {
      + h4 {
        margin-top: _rem(28px);
      }
    }

    #{$_}__link {
      a .btn {
        display: none;
      }
    }

    #{$_}__mobile-footer {
      display: flex;
      flex-direction: column;
      margin: _rem(48px) 0 _rem(48px);
    }

    &.v-enter-from, &.v-leave-to {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  }
}
</style>